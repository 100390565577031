// COLORS ============================
// black
$primary-black: #2C2C2C;
$secondary-black: #111;
$tertiary-black: #222;

// white
$primary-white: #fff;
$secondary-white: #eee;
$tertiary-white: #ddd;

// red
$primary-red: #f00;
$secondary-red: #e00;
$tertiary-red: #d00;
$quaternary-red: #ffc4c4;

//gray
$primary-gray: #F5F5F5;
$secondary-gray: #EFF2F4;
$tertiary-gray: #707070;
$quaternary-gray: #6C6C6C;

// theme
$primary-theme: #F4F3EF;
$secondary-theme: #E3E3E3;
$tertiary-theme: #FFFFFF;

// color
$primary-color: #FF6600;
$secondary-color: #9A9A9A;


// FONTS ============================
$primary-font-size: 18px;
$secondary-font-size: 15px;
$tertiary-font-size: 13px;

// BOX HEIGHT =======================
$primary-box: 7em;
$secondary-box: 5em;
$tertiary-box: 2em;

// DESIGN UTILS
$border-radius: 0.3em;
$padding-button: 1em;
$padding: 10px 15px 10px 15px;


// Table font size
$table-primary-font-size: 15px;
$table-header-sort: 9px;

// Table paddings
$table-primary-top-bottom: 15px;
$table-primary-left-right: 10px;
$table-primary-padding: $table-primary-top-bottom $table-primary-left-right;