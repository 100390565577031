@import "global/_library.scss";

// @import "global/layouts/alignments.scss";
@import "global/layouts/containers.scss";
// @import "global/layouts/sidenav.scss";
// @import "global/layouts/size.scss";
// @import "global/layouts/nav.scss";
// @import "global/layouts/sidenav.scss";

// @import "global/utils/cbm.scss";
// @import "global/utils/colors.scss";
// @import "global/utils/error-handling.scss";
// @import "global/utils/fonts.scss";


// @import "global/html_tags/button.scss";
// @import "global/html_tags/input.scss";
// @import "global/html_tags/ng-autocomplete.scss";
// @import "global/html_tags/ng-multiselect-dropdown.scss";
// @import "global/html_tags/select.scss";
// @import "global/html_tags/table.scss";

body, html {
    font-size: $secondary-font-size;

    label {
        margin-bottom: unset;
    }

    // Modal background
    .modal-backdrop {
        z-index: 2 !important;
    }
}
